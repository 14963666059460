<script lang="ts" setup>
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware-pwa/helpers-next";
import {
  mdiAccountOutline,
  mdiBellOutline,
  mdiChartBoxOutline,
  mdiClose,
  mdiHomeOutline,
  mdiLogin,
  mdiLogout,
} from "@mdi/js";
import { ref } from "vue";

const localePath = useLocalePath();
const { t } = useI18n();
const { formatLink } = useInternationalization(localePath);
const router = useRouter();
const loginModalController = useModal();
const { isLoggedIn, logout, user } = useUser();
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 1 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});

const props = withDefaults(
  defineProps<{
    canShowBanner?: boolean;
  }>(),
  {
    canShowBanner: false,
  },
);

const isAccountMenuOpen = ref(false);
const showDrawerMenu = ref(false);
const showDrawerAccount = ref(false);

const navIcon = computed(() => {
  if (showDrawerMenu.value) {
    return "$close";
  }
  return "$menu";
});

async function invokeLogout() {
  await logout();
  isAccountMenuOpen.value = false;
}

const goToRegister = () => {
  router.push(formatLink("/register"));
};

const openLoginModal = () => {
  loginModalController.open();
  showDrawerMenu.value = false;
};

const toggleAccountDrawer = () => {
  showDrawerAccount.value = !showDrawerAccount.value;
  showDrawerMenu.value = false;
};

const toggleMenuDrawer = () => {
  showDrawerMenu.value = !showDrawerMenu.value;
  showDrawerAccount.value = false;
};

const open = ref([]);

const loginDialogActive = ref(false);

const { apiClient } = useShopwareContext();

const dynamicIcon = ref("");
const bannerSettings = ref(null);
const fetchBannerSettings = async () => {
  try {
    const bannerSettingsResult = await apiClient
      .invoke("getBannerSettings get /banner-settings", {})
      .then((data) => {
        return data.data;
      });
    bannerSettings.value = bannerSettingsResult;

    if (bannerSettings.value.icon) {
      import("@mdi/js").then((icons) => {
        dynamicIcon.value = icons[bannerSettings.value.icon];
      });
    }
  } catch (e) {
    console.log(e);
  }
};
fetchBannerSettings();

const bannerClosed = useCookie("bannerClosed");
const closeBanner = () => {
  bannerClosed.value = true;
};
const bannerElement = ref(null);

const showBanner = computed(() => {
  return (
    bannerSettings.value?.active === true &&
    !bannerClosed.value &&
    props.canShowBanner
  );
});

const bannerHeight = computed(() => {
  if (!showBanner.value) return 0;
  return bannerElement.value?.clientHeight ?? 56;
});

const appBarHeight = computed(() => {
  return bannerHeight.value + 72;
});
</script>

<template>
  <v-app-bar :flat="true" :height="appBarHeight">
    <div class="w-100">
      <template v-if="showBanner">
        <div
          ref="bannerElement"
          :style="{ backgroundColor: bannerSettings.color }"
          class="w-100 d-flex align-center justify-center ps-2 pe-16 px-sm-16 text-center text-gray900 font-weight-bold position-relative"
        >
          <div class="w-100 d-flex align-center justify-center py-4">
            <v-icon
              v-if="bannerSettings.icon != null"
              :icon="dynamicIcon"
              class="me-2"
            />
            <component
              :is="bannerSettings.url ? 'a' : 'div'"
              :href="bannerSettings.url"
              class=""
              target="_blank"
            >
              {{ bannerSettings.text }}
            </component>
          </div>

          <v-btn
            class="position-absolute right-0 me-2"
            density="compact"
            flat
            icon
            size="large"
            style="z-index: 1"
            @click="closeBanner"
          >
            <v-icon :icon="mdiClose" />
          </v-btn>
        </div>
      </template>

      <div class="bg-gray950">
        <v-container>
          <v-row class="py-0 align-center flex-nowrap">
            <!-- Account Button (mobile) -->
            <v-col class="flex-grow-0 order-0 d-sm-none">
              <ClientOnly>
                <div v-show="!isLoggedIn">
                  <v-btn
                    class="d-sm-none"
                    color="white"
                    data-testid="header-sign-in-link"
                    icon
                    variant="text"
                    @click="loginDialogActive = true"
                  >
                    <v-icon :icon="mdiAccountOutline" />
                  </v-btn>
                </div>

                <div v-if="isLoggedIn">
                  <v-btn
                    class="d-sm-none"
                    color="white"
                    data-testid="header-sign-in-link"
                    icon
                    @click="toggleAccountDrawer"
                  >
                    <v-icon
                      v-if="!showDrawerAccount"
                      :icon="mdiAccountOutline"
                    />
                    <v-icon v-else icon="$close"></v-icon>
                  </v-btn>
                </div>
              </ClientOnly>
            </v-col>

            <!-- Logo -->
            <v-col
              class="flex-grow-1 flex-sm-grow-0 text-center text-sm-left order-1"
            >
              <v-app-bar-title>
                <NuxtLink :to="formatLink(`/`)">
                  <span class="sr-only">Shopware</span>
                  <img alt="Portallogo" class="header__logo" src="/logo.svg" />
                </NuxtLink>
              </v-app-bar-title>
            </v-col>

            <!-- Navigation (Desktop) -->
            <v-col class="order-2 d-none d-lg-block">
              <LayoutTopNavigation />
            </v-col>

            <!-- sign-in and sign-up buttons (> Phone) -->
            <v-col class="d-none d-sm-block order-3 text-right">
              <div v-if="!isLoggedIn" class="d-flex justify-end">
                <v-btn
                  :rounded="true"
                  class="text-capitalize font-weight-bold mr-3"
                  color="white"
                  variant="flat"
                  @click="goToRegister"
                >
                  Registrieren
                </v-btn>

                <v-btn
                  :prepend-icon="mdiLogin"
                  :rounded="true"
                  class="text-capitalize font-weight-bold"
                  color="primary"
                  density="default"
                  variant="flat"
                  @click="loginDialogActive = true"
                  >Login
                </v-btn>
              </div>

              <div v-if="isLoggedIn">
                <v-menu
                  :open-on-click="true"
                  :open-on-hover="true"
                  offset="12"
                  open-delay="60"
                >
                  <template #activator="{ props }">
                    <v-btn size="large" v-bind="props" variant="text">
                      <template #prepend>
                        <HwAvatar
                          :user="
                            user.extensions.customerOneCrowdFieldsExtension
                          "
                          color="gray100"
                          size="32"
                        />
                      </template>

                      <div class="d-flex flex-column">
                        <span
                          class="text-body-1 text-left font-weight-medium account-info"
                        >
                          {{ user.firstName }} {{ user.lastName }}
                        </span>

                        <span class="text-body-2 text-left account-info">
                          {{ user.email }}
                        </span>
                      </div>
                    </v-btn>
                  </template>

                  <v-list bg-color="gray800" class="rounded-t-0 rounded-b-lg">
                    <v-list-item>
                      <v-btn
                        :to="formatLink('/investor-portal')"
                        class="text-capitalize text-body-1"
                        data-testid="header-my-account-link"
                        tabindex="-1"
                        variant="text"
                      >
                        <template #prepend>
                          <v-icon :icon="mdiHomeOutline" />
                        </template>
                        {{ t('investorPortal.navigation.dashboard') }}
                      </v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-btn
                        :to="formatLink('/investor-portal/investments')"
                        class="text-capitalize text-body-1"
                        data-testid="header-my-account-link"
                        tabindex="-1"
                        variant="text"
                      >
                        <template #prepend>
                          <v-icon :icon="mdiChartBoxOutline" />
                        </template>
                        {{ t('investorPortal.navigation.investments') }}
                      </v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-btn
                        :prepend-icon="mdiAccountOutline"
                        :to="formatLink('/investor-portal/profile')"
                        class="text-capitalize text-body-1"
                        data-testid="header-my-account-link"
                        tabindex="-1"
                        variant="text"
                      >
                        {{ t("investorPortal.navigation.profile") }}
                      </v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-btn
                        :prepend-icon="mdiBellOutline"
                        :to="formatLink('/investor-portal/news')"
                        class="text-capitalize text-body-1"
                        data-testid="header-my-account-link"
                        tabindex="-1"
                        variant="text"
                      >
                        {{ t("investorPortal.navigation.news") }}
                      </v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-btn
                        :prepend-icon="mdiLogout"
                        class="text-capitalize text-body-1"
                        variant="text"
                        @click="invokeLogout"
                      >
                        {{ t("account.logout") }}
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>

            <!-- Menu Button ( < Desktop) -->
            <v-col class="order-4 d-lg-none flex-grow-0">
              <v-app-bar-nav-icon
                :icon="navIcon"
                aria-label="Show side menu"
                density="comfortable"
                @click="toggleMenuDrawer"
              >
              </v-app-bar-nav-icon>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-app-bar>

  <!-- Navigation Drawer -->
  <v-navigation-drawer
    v-model="showDrawerMenu"
    :disable-resize-watcher="true"
    :width="480"
    color="gray900"
    mobile-breakpoint="lg"
    temporary
  >
    <v-list v-model:opened="open">
      <template
        v-for="(navigationElement, index) in data"
        :key="navigationElement.id"
      >
        <ClientOnly>
          <v-list-group
            v-if="navigationElement?.children?.length"
            :value="navigationElement.name"
          >
            <template #activator="{ props }">
              <v-list-item
                class="py-3"
                v-bind="props"
                :to="formatLink(getCategoryRoute(navigationElement))"
                :title="getTranslatedProperty(navigationElement, 'name')"
              />
            </template>

            <v-list-item
              v-for="childElement in navigationElement.children"
              :key="childElement.id"
              :value="navigationElement.name"
              :to="formatLink(getCategoryRoute(childElement))"
              :title="getTranslatedProperty(childElement, 'name')"
            />
          </v-list-group>

          <v-list-item
            v-else
            class="py-3"
            :to="formatLink(getCategoryRoute(navigationElement))"
            :title="getTranslatedProperty(navigationElement, 'name')"
          />

          <v-divider
            v-if="index < data.length - 1"
            class="mx-4 my-2 border-opacity-100 border-gray700"
          />
        </ClientOnly>
      </template>
    </v-list>

    <div v-if="!isLoggedIn">
      <div class="d-block d-lg-none mx-4">
        <v-btn
          :block="true"
          :rounded="true"
          class="text-capitalize font-weight-bold mr-3 mb-4"
          color="white"
          variant="flat"
          @click="goToRegister"
        >
          {{ t("account.signUp") }}
        </v-btn>

        <v-btn
          :block="true"
          :prepend-icon="mdiLogin"
          :rounded="true"
          class="text-capitalize font-weight-bold"
          color="primary"
          density="default"
          variant="flat"
          @click="loginDialogActive = true"
        >
          {{ t("account.signIn") }}
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>

  <!-- Account Drawer (mobile) -->
  <v-navigation-drawer
    v-model="showDrawerAccount"
    :disable-resize-watcher="true"
    :width="480"
    color="gray900"
    mobile-breakpoint="lg"
    temporary
  >
    <v-list>
      <v-list-item class="py-3">
        <v-btn
          :prepend-icon="mdiHomeOutline"
          :to="formatLink('/investor-portal')"
          class="text-capitalize text-body-1"
          data-testid="header-my-account-link"
          tabindex="-1"
          variant="text"
        >
          Übersicht
        </v-btn>
      </v-list-item>

      <v-divider class="mx-4 my-2 border-opacity-100 border-gray700" />

      <v-list-item class="py-3">
        <v-btn
          :prepend-icon="mdiChartBoxOutline"
          :to="formatLink('/investor-portal/investments')"
          class="text-capitalize text-body-1"
          data-testid="header-my-account-link"
          tabindex="-1"
          variant="text"
        >
          Investments
        </v-btn>
      </v-list-item>

      <v-divider class="mx-4 my-2 border-opacity-100 border-gray700" />

      <v-list-item class="py-3">
        <v-btn
          :prepend-icon="mdiAccountOutline"
          :to="formatLink('/investor-portal/profile')"
          class="text-capitalize text-body-1"
          data-testid="header-my-account-link"
          tabindex="-1"
          variant="text"
        >
          Mein Profil
        </v-btn>
      </v-list-item>

      <v-divider class="mx-4 my-2 border-opacity-100 border-gray700" />

      <v-list-item class="py-3">
        <v-btn
          :prepend-icon="mdiBellOutline"
          :to="formatLink('/investor-portal/news')"
          class="text-capitalize text-body-1"
          data-testid="header-my-account-link"
          tabindex="-1"
          variant="text"
        >
          Neuigkeiten
        </v-btn>
      </v-list-item>

      <v-list-item class="py-3">
        <v-btn
          color="white"
          rounded
          block
          text="Logout"
          class="text-capitalize text-body-1 font-weight-bold"
          @click="invokeLogout"
        >
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-dialog v-model="loginDialogActive" width="auto">
    <v-card max-width="380">
      <v-card-text class="pa-4 pa-sm-8">
        <AccountLoginForm
          @close="loginDialogActive = false"
          @success="loginDialogActive = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.app-bar {
  height: var(--v-app-bar-height);
}

.header__logo {
  display: inline;
  height: 24px;
  min-width: 177px;
}

.account-info {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 176px;
}
</style>
